<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import _ from "lodash";
import { twMerge } from "tailwind-merge";
import { computed, ref, watch, type InputHTMLAttributes, useAttrs, inject } from "vue";
import { type ProvideFormInline } from "./FormInline.vue";
import { type ProvideInputGroup } from "./InputGroup/InputGroup.vue";
import { useField } from 'vee-validate';
import { useStringManipulation } from "@/helpers/string-manipulation";

const { capitaliseFirstLetter } = useStringManipulation();

interface FormTextareaProps extends /* @vue-ignore */ InputHTMLAttributes {
  modelValue?: InputHTMLAttributes["value"];
  formTextareaSize?: "sm" | "lg";
  rounded?: boolean;
  name: string;
  relativeError?: boolean;
}

const props = defineProps<FormTextareaProps>();
const emit = defineEmits(['update:modelValue']);
const attrs = useAttrs();
const formInline = inject<ProvideFormInline>("formInline", false);
const inputGroup = inject<ProvideInputGroup>("inputGroup", false);

const computedClass = computed(() =>
    twMerge([
      "disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent",
      "[&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent",
      "transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80",
      props.formTextareaSize == "sm" && "text-xs py-1.5 px-2",
      props.formTextareaSize == "lg" && "text-lg py-1.5 px-4",
      props.rounded && "rounded-full",
      formInline && "flex-1",
      inputGroup &&
      "rounded-none [&:not(:first-child)]:border-l-transparent first:rounded-l last:rounded-r z-10",
      typeof attrs.class === "string" && attrs.class,
    ])
);

let value = ref(props.modelValue || '');
let errorMessage = ref<string | null>(null);
let handleChange, handleBlur;

if (props.name) {
  const field = useField(props.name, undefined, {
    validateOnValueUpdate: false,
  });

  value = field.value;
  errorMessage = field.errorMessage;
  handleChange = field.handleChange;
  handleBlur = field.handleBlur;

  // Watch for changes in the VeeValidate field value and emit them
  watch(value, (newValue) => {
    emit('update:modelValue', newValue);
  });

  // Also watch for changes from the outer component and update the field
  watch(() => props.modelValue, (newValue) => {
    if (value.value !== newValue) {
      value.value = newValue;
    }
  });
} else {
  // Watch for changes from the outer component and update the local value
  watch(() => props.modelValue, (newValue) => {
    value.value = newValue;
  });

  watch(value, (newValue) => {
    emit('update:modelValue', newValue);
  });
}

const validationListeners = {
  blur: (evt: Event) => handleBlur && handleBlur(evt, true),
  change: (evt: Event) => handleChange && handleChange(evt),
  input: (evt: Event) => {
    const newValue = (evt.target as HTMLTextAreaElement).value;
    value.value = newValue;
    emit('update:modelValue', newValue);
    if (handleChange) {
      handleChange(evt, !!errorMessage.value);
    }
  },
};
</script>

<template>
  <div class="relative w-full">
    <textarea
        :class="[computedClass, errorMessage != null && '!border-red-600/80']"
        :name="props.name"
        v-bind="_.omit(attrs, 'class')"
        v-model="value"
        v-on="validationListeners"
    />
    <Transition>
      <div
          class='text-red-600/80 mt-1 text-[12px]'
          :class="!relativeError && 'absolute'"
          v-if="errorMessage"
      >
        {{ capitaliseFirstLetter(errorMessage) }}
      </div>
    </Transition>
  </div>
</template>

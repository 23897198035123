<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import _ from "lodash";
import { twMerge } from "tailwind-merge";
import {
  computed,
  type SelectHTMLAttributes,
  useAttrs,
  inject,
  ref,
  watch
} from "vue";
import { type ProvideFormInline } from "./FormInline.vue";

interface FormSelectProps extends /* @vue-ignore */ SelectHTMLAttributes {
  value?: SelectHTMLAttributes["value"];
  modelValue?: SelectHTMLAttributes["value"];
  formSelectSize?: "sm" | "lg";
  name: string,
  relativeError?: boolean
}

// interface FormSelectEmit {
//   (e: "update:modelValue", value: string): void;
// }

const selectRef = ref<HTMLSelectElement>();
const props = defineProps<FormSelectProps>();
const attrs = useAttrs();
const formInline = inject<ProvideFormInline>("formInline", false);

import { useStringManipulation } from "@/helpers/string-manipulation";
import {useField} from "vee-validate";
const {capitaliseFirstLetter} = useStringManipulation();

const computedClass = computed(() => {
      const isEmpty = localValue.value === "" || localValue.value === null || localValue.value === undefined;
      return twMerge([
        "disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50",
        "[&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50",
        "transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50",
        props.formSelectSize == "sm" && "text-xs py-1.5 pl-2 pr-8",
        props.formSelectSize == "lg" && "text-lg py-1.5 pl-4 pr-8",
        formInline && "flex-1",
        typeof attrs.class === "string" && attrs.class,
        isEmpty && "!text-slate-400/90" //

      ])

    }

);

// const emit = defineEmits<FormSelectEmit>();

const { value, errorMessage, handleChange } = useField(() => props.name, undefined, {
  validateOnValueUpdate: false
});

const localValue = computed({
  get() {
    if (props.modelValue === undefined && props.value === undefined) {
      const firstOption = selectRef.value?.querySelectorAll("option")[0];
      return (
          firstOption !== undefined &&
          (firstOption.getAttribute("value") !== null
              ? firstOption.getAttribute("value")
              : firstOption.text)
      );
    }

    return props.modelValue === undefined ? props.value : props.modelValue;
  },
  set(newValue) {
    handleChange(newValue);
    // emit("update:modelValue", newValue);
  },
});

// Watch for changes in localValue to apply the empty class
watch(localValue, (newVal) => {
  if (selectRef.value) {
    if (newVal === "" || newVal === null || newVal === undefined) {
      selectRef.value.classList.add("empty");
    } else {
      selectRef.value.classList.remove("empty");
    }
  }
});

</script>

<template>
  <div class="relative w-full">
    <select
        ref="selectRef"
        :class="computedClass"
        v-bind="_.omit(attrs, 'class')"
        v-model="localValue"
        :name="props.name"
    >
      <slot></slot>
    </select>
    <Transition>
      <div class='text-red-600/80 mt-1 text-[12px]' :class="!relativeError && 'absolute'" v-if="errorMessage">
        {{  capitaliseFirstLetter(errorMessage)  }}
      </div>
    </Transition>
  </div>

</template>

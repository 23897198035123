// src/composables/api/useUserApi.ts
import { useAuthHttp } from './useAuthHttp';
import { AxiosRequestConfig } from 'axios';

// import {Tournament} from '@/interfaces/TournamentInterface';


export function useTeamsApi() {
    const { get, post, put, del } = useAuthHttp();

    const storeTeam = async (formData: object, config: AxiosRequestConfig = {}): Promise => {
        return await post('/teams', formData, config);
    };

    const fetchTournamentTeam = async (tournamentTeamId: int, config: AxiosRequestConfig = {}): Promise => {
        return await get(`/tournament/team/${tournamentTeamId}`, config);
    };

    const inviteTeamMember = async (tournamentTeamId: int, formData:object,  config: AxiosRequestConfig = {}): Promise => {
        return await post(`/tournament/team/${tournamentTeamId}/invite-member`, formData, config);
    };

    const removeMemberOrInvitation = async (tournamentTeamId: int, formData:object,  config: AxiosRequestConfig = {}): Promise => {
        return await post(`/tournament/team/${tournamentTeamId}/remove-member-or-invitation`, formData, config);
    };

    const changeMemberOrInvitationRoles = async (tournamentTeamId: int, formData:object,  config: AxiosRequestConfig = {}): Promise => {
        return await post(`/tournament/team/${tournamentTeamId}/change-member-or-invitation-roles`, formData, config);
    };

    const changeMemberOrInvitationPermissions = async (tournamentTeamId: int, formData:object,  config: AxiosRequestConfig = {}): Promise => {
        return await post(`/tournament/team/${tournamentTeamId}/change-member-or-invitation-permissions`, formData, config);
    };

    const playerCompleteRegistration = async (tournamentTeamId: number, teamMemberId: number | null, formData: object, config: AxiosRequestConfig = {}): Promise => {
        // Conditionally build the URL to omit teamMemberId if it is null
        const url = teamMemberId !== null
            ? `/tournament/team/${tournamentTeamId}/player-complete-registration/${teamMemberId}`
            : `/tournament/team/${tournamentTeamId}/player-complete-registration/`;

        return await post(url, formData, config);
    };

    const payRegistrationFee = async (tournamentTeamId: int, formData:object,  config: AxiosRequestConfig = {}): Promise => {
        return await post(`/tournament/team/${tournamentTeamId}/payment`, formData, config);
    };

    const toggleCheckIn = async (memberId: int,  config: AxiosRequestConfig = {}): Promise => {
        return await post(`/member/${memberId}/toggle-check-in`, [], config);
    };

    const toggleUniformGiven = async (tournamentTeamId: int,  config: AxiosRequestConfig = {}): Promise => {
        return await post(`/tournament/team/${tournamentTeamId}/toggle-uniform-given`, [], config);
    };

    const sendInvitationReminder = async (invitationId: int,  config: AxiosRequestConfig = {}): Promise => {
        return await post(`/invitation/${invitationId}/send-invitation-reminder`, [], config);
    };

    const sendCompleteRegistrationReminder = async (memberId: int,  config: AxiosRequestConfig = {}): Promise => {
        return await post(`/member/${memberId}/send-complete-registration-reminder`, [], config);
    };


    return {
        storeTeam,
        fetchTournamentTeam,
        inviteTeamMember,
        payRegistrationFee,
        removeMemberOrInvitation,
        changeMemberOrInvitationRoles,
        changeMemberOrInvitationPermissions,
        playerCompleteRegistration,
        toggleCheckIn,
        toggleUniformGiven,
        sendInvitationReminder,
        sendCompleteRegistrationReminder
    };
}

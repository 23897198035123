export default {
    mounted(el) {
        el.addEventListener('keydown', function(event) {
            if (event.key === 'Enter') {
                event.preventDefault();

                // Find all focusable elements within the form
                const focusableElements = el.querySelectorAll('input, select, textarea, button');
                const focusableArray = Array.prototype.slice.call(focusableElements);

                // Get the currently focused element
                const currentIndex = focusableArray.indexOf(document.activeElement);

                // If we're on the last input or a submit button, submit the form
                const currentElement = focusableArray[currentIndex];
                if (currentElement.type === 'submit' || currentIndex === focusableArray.length - 1) {
                    currentElement.click(); // This should trigger the form submission
                } else {
                    // Otherwise, move focus to the next input
                    const nextElement = focusableArray[currentIndex + 1];
                    nextElement.focus();
                }
            }
        });
    },
};
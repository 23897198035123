// src/composables/api/useUserApi.ts
import { useAuthHttp } from './useAuthHttp';
import { AxiosRequestConfig } from 'axios';

// import {Tournament} from '@/interfaces/TournamentInterface';


export function useEventsApi() {
    const { get, post, put, del } = useAuthHttp();

    const storeEvent = async (formData: object, config: AxiosRequestConfig = {}): Promise => {
        return await post('/events', formData, config);
    };

    const fetchEvents = async (config: AxiosRequestConfig = {}): Promise => {
        return await get('/events', config);
    };
    const fetchEvent = async (eventSlug: string, config: AxiosRequestConfig = {}): Promise => {
        return await get(`/events/${eventSlug}`, config);
    };
    const fetchOpenTeamEvents = async (config: AxiosRequestConfig = {}): Promise => {
        return await get('/events/open-for-registration', config);
    };
    const fetchActiveEvents = async (config: AxiosRequestConfig = {}): Promise => {
        return await get('/events/active', config);
    };

    return {
        storeEvent,
        fetchEvents,
        fetchEvent,
        fetchOpenTeamEvents,
        fetchActiveEvents
    };
}

import { useForm } from 'vee-validate';
import * as yup from 'yup';

export function useFormHandler(validationSchema, onSubmitSuccess, customValidations = []) {
  const { values, errors, handleSubmit, setFieldError, setFieldValue } = useForm({
    validationSchema
  });

  const customValidate = () => {
    let customErrors = {};
    customValidations.forEach(validation => {
      const error = validation(values); // Ensure that values are passed
      if (error) {
        customErrors = { ...customErrors, ...error };
      }
    });
    return customErrors;
  };

  const handleError = (errors) => {

    const formElements = document.querySelectorAll('form [name]'); // Get all elements in the form with a name attribute
    let firstErrorElement = null;

    // Iterate over the form elements and find the first one that has an error
    for (let i = 0; i < formElements.length; i++) {
      const name = formElements[i].getAttribute('name');
      if (errors[name]) {
        firstErrorElement = formElements[i];
        break;
      }
    }

    if (firstErrorElement) {
      const yOffset = -200;
      const y = firstErrorElement.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const onSubmit = handleSubmit(
      (values) => {
        const customErrors = customValidate();
        if (Object.keys(customErrors).length === 0) {
          onSubmitSuccess(values);
        } else {
          // Merge custom errors with the existing errors
          Object.keys(customErrors).forEach((key) => {
            setFieldError(key, customErrors[key]);
          });
          handleError(customErrors);
        }
      },
      ({ errors }) => {
        handleError(errors);
      },
  );

  return {
    values,
    errors,
    onSubmit,
    setFieldValue
  };
}

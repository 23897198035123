<script setup>
  import NotificationManager from "@/components/App/Base/Notification/NotificationManager.vue";
  import ContactUs from "@/components/App/ContactUs/ContactUs.vue";

  import { useAuthStore } from "@/stores/app/AuthStore";
  const authStore = useAuthStore();

</script>


<template>
  <div v-if="authStore.isAppReady">
    <RouterView />
    <NotificationManager/>
    <ContactUs/>
  </div>
</template>
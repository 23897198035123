import { createApp } from "vue";
import { createPinia } from "pinia";
import { useAuthStore } from "@/stores/app/AuthStore";
import App from "./App.vue";
import { router } from "@/router";
import auth from "@/plugins/auth";
import "./assets/css/app.css";
import axios from 'axios'
import preventEnterSubmit from '@/directives/v-prevent-enter-submit';
import { useMenuStore } from "@/stores/menu";
import { useAppStore } from "@/stores/app/AppStore";
import * as Sentry from "@sentry/vue";


import vue3GoogleLogin from 'vue3-google-login'

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;


//stores


const app = createApp(App);

app.use(createPinia());

app.use(router);
app.use(auth);
app.use(vue3GoogleLogin, {
    clientId: '557688370087-qqm96b6qkbjjj9a75fbsre6fdjovv3rf.apps.googleusercontent.com'
})

if(import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            "localhost",
            /^https:\/\/app\.summerjam\.com\.au/,
            /^https:\/\/staging\.summerjam\.com\.au/,
            /^https:\/\/api\.summerjam\.com\.au/,
            /^https:\/\/api-staging\.summerjam\.com\.au/
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

// Global error handler
app.config.errorHandler = async (err, vm, info) => {
    console.error('Global error caught:', err); // Log the error

    // Send the error to Sentry
    Sentry.captureException(err);

    const appStore = useAppStore(); // Access the app store

    try {
        //this method refreshes if it's not
        await appStore.checkLatestVersion;
    } catch (error) {
        //we have run into an error, refresh the page
        appStore.refreshAndRedirectToHome('There was an error. You will be redirect to the home page')
    }
};

// Global promise rejection handler for unhandled rejections
window.addEventListener('unhandledrejection', function (event) {
    const appStore = useAppStore();

    if (event.reason.includes('Google 3P Authorization')) {
        appStore.googleLoginLibraryFailed = true;
    }
    // You can add more conditions here to handle other promise rejections globally
});

// Add error handler for missing assets (JS and CSS)
let hasAlerted = false;  // Flag to ensure alert is shown only once

window.addEventListener('error', function(event) {
    const tagName = event.target.tagName;
    const isAssetsFolder = event.target.src?.includes('/assets/') || event.target.href?.includes('/assets/');

    if ((tagName === 'SCRIPT' || tagName === 'LINK') && isAssetsFolder) {
        // Capture the missing asset error in Sentry
        Sentry.captureException(new Error(`Old Asset detected (page reloading): ${event.target.src || event.target.href}`));

        if (!hasAlerted) {  // Ensure alert is shown only once
            hasAlerted = true;  // Set the flag to true so the alert won't trigger again
            alert('A new version of the app is available. Your page will refresh to update.');  // Show alert to the user
            window.location.reload();  // Refresh the page
        }
    }
}, true);



// we always want to refresh the token first
const initializeApp = async () => {
    const authStore = useAuthStore();
    const menuStore= useMenuStore();
    const appStore= useAppStore();

    //Before route
    router.beforeEach(async (to, from, next) => {

        //we first refresh token, if it fails we don't need to do anything because the router will redirect
        if(authStore.isAppReady == false) {
            try {
                await authStore.refreshToken();
                await authStore.getUserDetails();
                appStore.initialiseStore();
            }
            catch(error) {
                authStore.tokenValid = false;
            }
        }

        // var hasPermission = await authStore.doesUserHavePermissionToRoute( initialRoute);
        await authStore.checkUserPermissions(to, from, next);
        authStore.isAppReady = true
    })

    router.afterEach((to, from) => {
        menuStore.activeMobileMenu = false
    })

    // Ready to mount app immediately
    app.mount("#app");
    app.directive('prevent-enter-submit', preventEnterSubmit);


}

initializeApp();

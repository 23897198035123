// stores/notificationStore.ts
import { defineStore } from 'pinia';
import Toastify from 'toastify-js';

interface Notification {
  id: number;
  message: string;
  options?: ToastOptions;
  toastInstance?: Toastify.ToastifyInstance; // Add Toastify instance reference
}

interface ToastOptions {
  duration?: number;
  close?: boolean;
  gravity?: 'top' | 'bottom';
  position?: 'left' | 'center' | 'right';
  stopOnFocus?: boolean;
  type?: 'success' | 'danger';
}

const softSuccess = [
  "bg-success border-success border-opacity-5 text-success", // Default
  "dark:border-success ", // Dark mode
];

const softDanger = [
  "bg-danger border-danger bg-opacity-20 border-opacity-5 text-danger", // Default
  "dark:border-danger dark:border-opacity-20", // Dark mode
];

export const useNotificationStore = defineStore('notificationStore', {
  state: () => ({
    notifications: [] as Notification[],
  }),
  actions: {
    showNotification(message: string, options?: ToastOptions) {
      const id = Date.now();

      // Set type default to 'success' if not provided
      const type = options?.type ?? 'success';

      // Determine the class based on the type ('success' or 'danger')
      let classList = 'custom-toast';
      if (type === 'success') {
        classList += ' ' + softSuccess.join(' ');
      } else if (type === 'danger') {
        classList += ' ' + softDanger.join(' ');
      }

      const toastInstance = Toastify({
        text: message,
        duration: options?.duration ?? 3000,
        close: options?.close ?? true,
        gravity: options?.gravity ?? 'top',
        position: options?.position ?? 'right',
        stopOnFocus: options?.stopOnFocus ?? true,
        className: classList, // Apply the generated class list
        onClose: () => {
          this.removeNotification(id); // Remove the notification when the toast is closed
        },
      });

      toastInstance.showToast();
      this.notifications.push({ id, message, options, toastInstance });

      var self = this
    },
    removeNotification(id: number) {
      const notification = this.notifications.find(n => n.id === id);
      if (notification?.toastInstance) {
        notification.toastInstance.hideToast(); // Hide the toast if it exists
      }
      this.notifications = this.notifications.filter(notification => notification.id !== id);
    },
    removeAllNotifications() {
      this.notifications.forEach(notification => {
        if (notification.toastInstance) {
          notification.toastInstance.hideToast(); // Hide each active toast
        }
      });
      this.notifications = []; // Clear the notifications array
    },
  },
});

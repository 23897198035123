
export function useHelpers() {

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function isEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function isUnder18(dateOfBirth) {


    // Ensure the date is properly handled as a Date object
    const birthDate = new Date(dateOfBirth);

    // Check if the date is valid
    if (isNaN(birthDate.getTime())) {
      return false
    }

    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age < 18;

  };

  function getFirstName(fullName:string) {
    // Split the full name into an array of words
    const nameParts = fullName.trim().split(" ");
    // Return the first word in the array, which is the first name
    return nameParts[0];
  }

  function convertTimeToMeridian(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12; // Convert 0 hours to 12

    return `${adjustedHours}${period}`;
  }

  function matchWidths(selector) {
    const elements = document.querySelectorAll(selector);
    let maxWidth = 0;

    // Reset all element widths to auto before recalculating
    elements.forEach(el => {
      el.style.width = 'auto';
    });

    // Find the maximum width
    elements.forEach(el => {
      const width = el.offsetWidth;
      if (width > maxWidth) {
        maxWidth = width;
      }
    });

    // Set all elements to the maximum width
    elements.forEach(el => {
      el.style.width = `${maxWidth}px`;
    });

    // Add resize event listener to recalculate widths on window resize
    const handleResize = () => {
      elements.forEach(el => {
        el.style.width = 'auto'; // Reset widths again
      });

      maxWidth = 0; // Reset maxWidth
      elements.forEach(el => {
        const width = el.offsetWidth;
        if (width > maxWidth) {
          maxWidth = width;
        }
      });

      elements.forEach(el => {
        el.style.width = `${maxWidth}px`; // Set new max width
      });
    };

    window.addEventListener('resize', handleResize);

    // Optionally: Clean up event listener when it's no longer needed (not part of this function directly)
  }

  function calculateStripeFees(amount) {
    const baseFee = amount; // Base fee (the amount you pass in)

    // Stripe Fee Calculation
    const stripePercentage = 0.0175; // 1.75%
    const fixedStripeFee = 0.30; // 0.30 cents

    // Calculate the Stripe fee
    const stripeFee = (baseFee * stripePercentage) + fixedStripeFee;

    // Total Fee (base fee + Stripe fee)
    const totalFee = baseFee + stripeFee;

    // Return an object with the calculated fees
    return {
      baseFee: baseFee.toFixed(2),
      stripeFee: stripeFee.toFixed(2),
      totalFee: totalFee.toFixed(2)
    };
  }


  return { delay, isEmail, isUnder18, getFirstName, convertTimeToMeridian, matchWidths, calculateStripeFees }
}

export type HelpersType = ReturnType<typeof useHelpers>;
import { useAuthHttp } from './useAuthHttp';
import { AxiosRequestConfig } from 'axios';


export function useEmailTemplatesApi() {
    const { get, post, put, del, patch } = useAuthHttp();

    const fetchEmailTemplates = async (config: AxiosRequestConfig = {}): Promise<array> => {
        return await get<array>('/email-templates', config);
    };

    const updateEmailTemplate = async (templateId: int, formData: object, config: AxiosRequestConfig = {}): Promise<array> => {
        return await patch<array>(`/email-templates/${templateId}`, formData,config);
    };

    return {
        fetchEmailTemplates,
        updateEmailTemplate
    };
}

// src/composables/api/useUserApi.ts
import { useAuthHttp } from './useAuthHttp';
import { AxiosRequestConfig } from 'axios';


export function useUserApi() {
    const { get, post, put, del } = useAuthHttp();

    // const fetchUsers = async (config: AxiosRequestConfig = {}): Promise<User[]> => {
    //     return await get<User[]>('/users', config);
    // };
    //
    // const fetchUser = async (id: number, config: AxiosRequestConfig = {}): Promise<User> => {
    //     return await get<User>(`/users/${id}`, config);
    // };

    const fetchUsersInvitations = async (config: AxiosRequestConfig = {}): Promise => {
        return await get(`/user/invitation`, config);
    };

    const actionInvitation = async (id:number, invitationActionData: object,config: AxiosRequestConfig = {}): Promise => {
        return await post(`/user/invitation/action/${id}`, invitationActionData, config);
    };

    const updateProfileInfo = async (profileInformation: object,config: AxiosRequestConfig = {}): Promise => {
        return await post(`/user/update/profile`, profileInformation, config);
    };

    const contactEnquiry = async (contactObject: object,config: AxiosRequestConfig = {}): Promise => {
        return await post(`/contact-form-enquiry`, contactObject, config);
    };

    // const addUser = async (userData: User, config: AxiosRequestConfig = {}): Promise<User> => {
    //     return await post<User>('/users', userData, config);
    // };
    //
    // const updateUser = async (id: number, userData: User, config: AxiosRequestConfig = {}): Promise<User> => {
    //     return await put<User>(`/users/${id}`, userData, config);
    // };
    //
    // const deleteUser = async (id: number, config: AxiosRequestConfig = {}): Promise<void> => {
    //     return await del<void>(`/users/${id}`, config);
    // };

    return {
        fetchUsersInvitations,
        actionInvitation,
        updateProfileInfo,
        contactEnquiry
    };
}

import { defineStore } from "pinia";
import {useAuth, useUser} from "vue-auth3"
import { useRouter } from "vue-router";
import { useLocalStorage } from "@/helpers/localStorage";
import { useAuthApi } from "@/composables/api/useAuthApi";
import { useHelpers } from "@/composables/Helpers";
import { HelpersType } from "@/composables/Helpers";
import { UserRoleEnum } from '@/enums';
import { useMenuStore } from "@/stores/menu";
import { useAppStore } from "@/stores/app/AppStore";

// const { fetchMe } = useUserApi();
export interface AuthStore {
  redirectPage: string
  auth: any
  user?: object,
  helpers: HelpersType,
  router: object,
  useAuthApi: any,
  appReady: false,
  tokenValid: boolean,
}

export const useAuthStore = defineStore("auth", {
  
  state: (): AuthStore => ({
    redirectPage: "",
    auth: useAuth(),
    router: useRouter(),
    helpers: useHelpers(),
    user: undefined,
    useAuthApi: useAuthApi(),
    isAppReady: false,
    tokenValid: false,
  }),
  getters: {
    
  },
  actions: {
    async login(email: string|null, password: string|null, google_code: string|null, remember_me:string) {
      try {
        const res = await this.auth.login({
          data: {
            email: email,
            password: password,
            google_code:google_code
          },
          staySignedIn: remember_me == "true" ? true : false,
          fetchUser: false,
          redirect: false
        })

        // this.setToken(res.data.token);

        // this.getUserDetails();
        this.tokenValid = true;
        await this.getUserDetails();
        this.redirectToPreviousPage();

      } catch (error) {
        return Promise.reject(error);
      }       
    },


    async refreshToken() {
      try {
        const appStore = useAppStore();

        // Refresh the token
        const res = await this.auth.refresh();

        // Now set the new token


        this.setToken(res.data.token);

        // Store the latest app version from the response
        const latestAppVersion = res.data.app_version;
        appStore.setLatestAppVersion(latestAppVersion);
        appStore.setCurrentAppVersion(latestAppVersion);

        // Resolve the promise
        return Promise.resolve('Success!');

      } catch (error) {
        // Handle the error
        return Promise.reject(error);
      }
    },








    setToken(token: string) {
      this.auth.token(token);
      this.tokenValid = true;
    },



    async getUserDetails() {

      const menuStore= useMenuStore();

      const { fetchMe } = this.useAuthApi;
      //if user details are empty then we load the user details
      try {
        // this.refreshToken();
        // this.refreshToken();
        // this.refreshToken();
        var userDetails = await fetchMe();
        this.user = userDetails;
        menuStore.updateDynamicMenu();
        return true;
      }
      catch(error) {
        //not logged in
        return false;
      }

    },

    logout() {
      this.auth.logout({
        makeRequest: true,
        redirect: { name: "login" },
      });
    },

    async checkUserPermissions(to, from, next) {



      try {
        const authorised = await this.doesUserHavePermissionToRoute( to);

        if (authorised) {
          next();
        } else {
          next('/login');
        }
      } catch (error) {
        next('/login');
      }
    },

    redirectToPreviousPage() {
      var prevPage = this.getPreviousPage();
      this.removePreviousPage();


      if(!prevPage) {
        this.router.push('/');
      }
      else {
        this.router.push({path:prevPage})
      }
    },    
    storePreviousPage(to) {
      const {setItem} = useLocalStorage();
      setItem('auth_redirect_prev_page', to.fullPath);
    },
    getPreviousPage() {
      const {getItem} = useLocalStorage();
      return getItem('auth_redirect_prev_page');
    },
    removePreviousPage() {
      const {removeItem} = useLocalStorage();
      removeItem('auth_redirect_prev_page');
    },
    async doesUserHavePermissionToRoute(to) {

      var authorised = true;
      //check if we need auth
      try {
        if(to.meta.custom_auth && to.meta.custom_auth.required == true) {
          //get the user details, if this fails then user not logged in
          // authorised = await this.getUserDetails();
          //
          if(this.tokenValid == false) {
            this.notAuthorisedRedirect(to, false);
            throw new Exception('Not Authorised');
          }

          //not check if we have appropriate roles and permissions
          authorised = await this.userPermittedRouteRoles(to);
          if(authorised == false) {
            this.notAuthorisedRedirect(to, true)
            throw new Exception('Not Authorised');
          }

          //not check if we have appropriate roles and permissions
          authorised = await this.userPermittedRoutePermissions(to);
          if(authorised == false) {
            this.notAuthorisedRedirect(to, true)
            throw new Exception('Not Authorised');
          }
        }
      }
      catch {
        //we go in here we set authorised to false just in case
        authorised = false;
      }


      //if we reached here we are all authorised
      return authorised;

    },
    notAuthorisedRedirect(to, loggedIn:boolean) {
      this.storePreviousPage(to);
      if(loggedIn) {
        this.router.push('/')
      }
      else {
        this.router.push(this.auth.options.authRedirect)
      }

    },

    userPermittedRouteRoles(to) {
      if(to.meta.custom_auth.roles && to.meta.custom_auth.roles.length > 0) {
        return this.doesUserHaveRole(to.meta.custom_auth.roles);
      }

      return true;
    },
    doesUserHaveRole(roles: Array<T> | string) {
      var found = false;
      //check to see if the user has at least one of the roles required
      if(this.user) {
        if(typeof roles == 'string') {
          found = this.user.user_roles.includes(roles);
        }
        else {
          found = this.user.user_roles.some(
              role => roles.includes(role)
          )
          //if we are admin or super admin, we let those users through
          if(this.user.user_roles.includes(UserRoleEnum.SUPERADMIN) || this.user.user_roles.includes(UserRoleEnum.ADMIN)) {
            found = true;
          }
        }
      }
      return found;
    },
    userPermittedRoutePermissions(to) {
      if(to.meta.custom_auth.permissions && to.meta.custom_auth.permissions.length > 0) {
        return this.doesUserHavePermission(to.meta.custom_auth.permissions);
      }

      return true;
    },
    doesUserHavePermission(permissions: Array<T> | string) {
      var found = false;

      //check to see if the user has at least one of the roles required
      if(this.user) {
        if(typeof roles == 'string') {
          found = this.user.user_permissions.includes(permissions);
        }
        else {
          found = this.user.user_permissions.some(
              permission => permissions.includes(role)
          )
        }

        //if we are admin or super admin, we let those users through
        if(this.user.user_roles.includes(UserRoleEnum.SUPERADMIN) || this.user.user_roles.includes(UserRoleEnum.ADMIN)) {
          found = true;
        }
      }
      return found;
    },
  }
});
import { useAuth } from 'vue-auth3';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useRouter } from 'vue-router'; // Assuming you're using Vue Router

export function useAuthHttp() {
    const auth = useAuth();
    const router = useRouter();

    const request = async <T = any>(config: AxiosRequestConfig, fullResponse = false): Promise<T | AxiosResponse<T>> => {
        const defaultConfig: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            const response: AxiosResponse<T> = await auth.http({
                ...defaultConfig,
                ...config,
                headers: {
                    ...defaultConfig.headers,
                    ...config.headers,
                },
                method: config.method || 'get',
            });

            // Conditionally return the full response or just the data
            return fullResponse ? response : response.data;
        } catch (error) {
            // Check if the error is due to an expired or invalid token
            if (error.response && error.response.status === 401) {
                try {
                    // Attempt to refresh the token
                    await auth.refresh();

                    // Retry the original request with the new token
                    const retryResponse: AxiosResponse<T> = await auth.http({
                        ...defaultConfig,
                        ...config,
                        headers: {
                            ...defaultConfig.headers,
                            ...config.headers,
                        },
                        method: config.method || 'get',
                    });

                    return fullResponse ? retryResponse : retryResponse.data;
                } catch (refreshError) {
                    // If refresh fails, redirect to login
                    console.error('Token refresh failed:', refreshError);
                    router.push(auth.options.authRedirect); // Replace 'login' with your actual login route name
                    throw refreshError;
                }
            }

            console.error('HTTP request error:', error);
            throw error;
        }
    };

    const get = async <T = any>(url: string, config: AxiosRequestConfig = {}, fullResponse = false): Promise<T | AxiosResponse<T>> => {
        return request<T>({ ...config, url, method: 'get' }, fullResponse);
    };

    const post = async <T = any>(url: string, data: any, config: AxiosRequestConfig = {}, fullResponse = false): Promise<T | AxiosResponse<T>> => {
        return request<T>({ ...config, url, method: 'post', data }, fullResponse);
    };

    const put = async <T = any>(url: string, data: any, config: AxiosRequestConfig = {}, fullResponse = false): Promise<T | AxiosResponse<T>> => {
        return request<T>({ ...config, url, method: 'put', data }, fullResponse);
    };

    const patch = async <T = any>(url: string, data: any, config: AxiosRequestConfig = {}, fullResponse = false): Promise<T | AxiosResponse<T>> => {
        return request<T>({ ...config, url, method: 'patch', data }, fullResponse);
    };

    const del = async <T = any>(url: string, config: AxiosRequestConfig = {}, fullResponse = false): Promise<T | AxiosResponse<T>> => {
        return request<T>({ ...config, url, method: 'delete' }, fullResponse);
    };

    return {
        get,
        post,
        put,
        patch,
        del,
    };
}

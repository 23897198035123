// src/composables/api/useUserApi.ts
import { useAuthHttp } from './useAuthHttp';
import { AxiosRequestConfig } from 'axios';

interface User {
    id: number;
    name: string;
    email: string;
    // other user properties
}

export function useAuthApi() {
    const { get, post, put, del } = useAuthHttp();

    // const fetchUsers = async (config: AxiosRequestConfig = {}): Promise<User[]> => {
    //     return await get<User[]>('/users', config);
    // };
    //
    // const fetchUser = async (id: number, config: AxiosRequestConfig = {}): Promise<User> => {
    //     return await get<User>(`/users/${id}`, config);
    // };

    const fetchMe = async (config: AxiosRequestConfig = {}): Promise<User> => {
        return await get<User>(`/auth/user`, config);
    };

    const register = async (config: AxiosRequestConfig = {}): Promise<User> => {
        return await post<User>(`/auth/register`, config);
    };

    const verifyEmail = async (token:string,config: AxiosRequestConfig = {}): Promise<User> => {
        return await get<User>(`/auth/verify-email/${token}/`, config);
    };

    const resendEmailVerification = async (formData:object, config: AxiosRequestConfig = {}): Promise<User> => {
        return await post(`/auth/verify-email/resend/`, formData, config);
    };

    const resetPasswordCheck = async (token:string, config: AxiosRequestConfig = {}): Promise<User> => {
        return await get(`/auth/reset-password/check/${token}/`,  config);
    };

    const resetPassword = async (formData:object, config: AxiosRequestConfig = {}): Promise<User> => {
        return await post(`/auth/reset-password`, formData, config);
    };

    const resendPasswordReset = async (formData:object, config: AxiosRequestConfig = {}): Promise<User> => {
        return await post(`/auth/reset-password/resend/`, formData, config);
    };

    const forgotPassword = async (config: AxiosRequestConfig = {}): Promise<User> => {
        return await post(`/auth/forgot-password`, config);
    };




    // const addUser = async (userData: User, config: AxiosRequestConfig = {}): Promise<User> => {
    //     return await post<User>('/users', userData, config);
    // };
    //
    // const updateUser = async (id: number, userData: User, config: AxiosRequestConfig = {}): Promise<User> => {
    //     return await put<User>(`/users/${id}`, userData, config);
    // };
    //
    // const deleteUser = async (id: number, config: AxiosRequestConfig = {}): Promise<void> => {
    //     return await del<void>(`/users/${id}`, config);
    // };

    return {
        // fetchUsers,
        // fetchUser,
        // addUser,
        // updateUser,
        // deleteUser,
        fetchMe,
        register,
        verifyEmail,
        resendEmailVerification,
        resetPassword,
        resetPasswordCheck,
        resendPasswordReset,
        forgotPassword
    };
}

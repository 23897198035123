import { createRouter, createWebHistory } from "vue-router";
import Layout from "@/themes";
import { UserRoleEnum } from '@/enums'
import { useTournamentsApi } from "@/composables/api/useTournamentsApi";
import { useEventsApi } from "@/composables/api/useEventsApi";
import { useEmailTemplatesApi } from "@/composables/api/useEmailTemplatesApi";
import {useTeamsApi} from "@/composables/api/useTeamsApi";
import {useAuthApi} from "@/composables/api/useAuthApi";
import {useUserApi} from "@/composables/api/useUserApi";
import { useAuthStore } from "@/stores/app/AuthStore";
import { useLocalStorage } from "@/helpers/localStorage";


import { ErrorCodeEnum } from '@/enums'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve({ ...savedPosition, behavior: 'smooth' });
        } else {
          resolve({ top: 0, behavior: 'smooth' });
        }
      }, 300);
    });
  },
  routes: [
    {
      path: "/",
      component: Layout,

      children: [
        {
          path: "/team-registration",
          name: "team-registration",
          component: () => import("@/pages/app/team/Registration/TeamRegistration.vue"),
          meta: {
            heading: 'Team Registration',
            custom_auth: {
              required: true,
              roles:[],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Team Registration" }
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchOpenTeamEvents } = useEventsApi();
            const authStore = useAuthStore();  // Create an instance of AuthStore
            //fetch what we need
            try {
              const fetchedEvents = await fetchOpenTeamEvents();
              to.params.events = fetchedEvents.data;
            }
            catch {

            }

            next();

          },
        },
        {
          path: "/uniform-allocation",
          name: "uniform-allocation",
          component: () => import("@/pages/app/UniformAllocation.vue"),
          meta: {
            heading: 'Uniform Allocation',
            custom_auth: {
              required: true,
              roles:[UserRoleEnum.PARTNER],
              permissions:[]
            }
          }
        },
        {
          path: "/event/create",
          name: "create-event",
          component: () => import("@/pages/app/event/CreateEvent.vue"),
          meta: {
            heading: 'Create Event',
            custom_auth: {
              required: true,
              roles:[UserRoleEnum.SUPERADMIN, UserRoleEnum.ADMIN],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Create Event" }
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchTournaments } = useTournamentsApi();
            const authStore = useAuthStore();  // Create an instance of AuthStore

            try {
              const fetchedTournaments = await fetchTournaments();
              to.params.tournaments = fetchedTournaments.data;
            } catch {}

            next();
          },
        },
        {
          path: "/email-templates",
          name: "email-templates",
          component: () => import("@/pages/app/email-templates/Index.vue"),
          meta: {
            heading: 'Email Templates',
            custom_auth: {
              required: true,
              roles:[UserRoleEnum.SUPERADMIN, UserRoleEnum.ADMIN],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Email Templates" }
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchEmailTemplates } = useEmailTemplatesApi();
            const authStore = useAuthStore();  // Create an instance of AuthStore

            try {
              const fetchedEmailTemplates = await fetchEmailTemplates();
              to.params.emailTemplates = fetchedEmailTemplates.data;
            } catch {}

            next();
          },
        },
        {
          path: "/events",
          name: "view-events",
          component: () => import("@/pages/app/event/Index.vue"),
          meta: {
            heading: 'Events',
            custom_auth: {
              required: true,
              roles:[UserRoleEnum.MANAGER],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Events" }
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchEvents } = useEventsApi();
            const authStore = useAuthStore();

            try {
              const fetchedEvents = await fetchEvents();
              to.params.events = fetchedEvents.data;
            } catch {}

            next();

          },
        },
        {
          path: "/tournaments",
          name: "view-tournaments",
          component: () => import("@/pages/app/management/Tournaments.vue"),
          meta: {
            heading: 'Tournaments',
            custom_auth: {
              required: true,
              roles:[UserRoleEnum.MANAGER],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Tournaments" }
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchActiveEventTournaments } = useTournamentsApi();
            const authStore = useAuthStore();

            try {
              const fetchedTournaments = await fetchActiveEventTournaments();
              to.params.tournaments = fetchedTournaments.data;
            } catch {}

            next();

          },
        },
        {
          path: "/teams/active",
          name: "view-active-tournament-teams",
          component: () => import("@/pages/app/management/Teams.vue"),
          meta: {
            heading: 'Teams',
            custom_auth: {
              required: true,
              roles:[UserRoleEnum.MANAGER],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Teams" }
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchActiveEventTournamentTeams } = useTournamentsApi();
            const authStore = useAuthStore();

            try {
              const fetchedTeams = await fetchActiveEventTournamentTeams();
              to.params.teams = fetchedTeams.data;
            } catch {}

            next();

          },
        },
        {
          path: "/players/active",
          name: "view-active-players",
          component: () => import("@/pages/app/management/Players.vue"),
          meta: {
            heading: 'Players',
            custom_auth: {
              required: true,
              roles: [UserRoleEnum.MANAGER],
              permissions: []
            },
            breadcrumb: route => [
              {label: "Players"}
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchActiveEventTournamentTeams } = useTournamentsApi();
            const authStore = useAuthStore();

            try {
              const fetchedTeams = await fetchActiveEventTournamentTeams();
              to.params.teams = fetchedTeams.data;
            } catch {}

            next();

          },
        },
        {

          path: "/team/check-in/",
          name: "team-check-in",
          component: () => import("@/pages/app/staff/CheckIn/CheckIn.vue"),
          meta: {
            heading: 'Check In',
            custom_auth: {
              required: true,
              roles:[UserRoleEnum.STAFF],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Check In" }
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchActiveEventTournamentTeams } = useTournamentsApi();
            const { fetchActiveEvents } = useEventsApi();
            const authStore = useAuthStore();

            try {
              const fetchedTeams = await fetchActiveEventTournamentTeams();
              const fetchedEvents = await fetchActiveEvents();
              to.params.events = fetchedEvents.data;
              to.params.teams = fetchedTeams.data;
            } catch {}

            next();

          },
        },
        {
          path: "/event/:eventId",
          name: "view-event",
          component: () => import("@/pages/app/event/Show.vue"),
          meta: {
            heading: 'Event',
            custom_auth: {
              required: true,
              roles:[UserRoleEnum.MANAGER],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Events", link: '/events' },
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchEvent } = useEventsApi();
            const authStore = useAuthStore();

            try {
              const fetchedEvent = await fetchEvent(to.params.eventId);
              to.params.event = fetchedEvent.data;

              // Update breadcrumb with actual event name after fetching the event
              to.meta.breadcrumb = [
                { label: "Events", link: '/events' },
                { label: to.params.event.name }
              ];

            } catch {

            }

            next();

          },
        },
        {
          path: "/event/:eventId/tournament/:tournamentId/teams",
          name: "event-tournament-teams",
          component: () => import("@/pages/app/event/Event/Teams.vue"),
          meta: {
            heading: 'Teams',
            custom_auth: {
              required: true,
              roles:[UserRoleEnum.MANAGER],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Teams" },
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchEvent } = useEventsApi();

            try {

              const fetchedEvent = await fetchEvent(to.params.eventId);
              var event = fetchedEvent.data;
              to.params.event = event;
              to.params.tournament = event.tournaments.find(tournament => tournament.id == to.params.tournamentId)

              // Update breadcrumb with actual event name after fetching the event
              to.meta.breadcrumb = [
                { label: "Events", link: '/events' },
                { label: to.params.event.name, link: `/event/${to.params.event.id}` },
                { label: to.params.tournament.name },
              ];

            } catch(error) {
              console.log(error)
            }

            next();

          },
        },
        {
          path: "/event/:eventId/tournament/:tournamentId/teams/:teamId/players",
          name: "event-tournament-team-players",
          component: () => import("@/pages/app/event/Event/Players.vue"),
          meta: {
            heading: 'Players',
            custom_auth: {
              required: true,
              roles:[UserRoleEnum.MANAGER],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Teams" },
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchEvent } = useEventsApi();

            try {

              const fetchedEvent = await fetchEvent(to.params.eventId);
              var event = fetchedEvent.data;
              to.params.event = event;
              to.params.tournament = event.tournaments.find(tournament => tournament.id == to.params.tournamentId)

              // Update breadcrumb with actual event name after fetching the event
              to.meta.breadcrumb = [
                { label: "Events", link: '/events' },
                { label: to.params.event.name, link: `/event/${to.params.event.id}` },
                { label: to.params.tournament.name, link: `/event/${to.params.event.id}/tournament/${to.params.tournament.id}` },
                { label: 'To Do'}
              ];

            } catch(error) {
              console.log(error)
            }

            next();

          },
        },
        {
          path: "/event/:eventId/tournament/:tournamentId/edit",
          name: "edit-event-tournament",
          component: () => import("@/pages/app/event/TournamentEdit.vue"),
          meta: {
            heading: 'Tournament',
            custom_auth: {
              required: true,
              roles:[UserRoleEnum.MANAGER],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Events", link: '/events' },
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchTournament } = useTournamentsApi();
            const authStore = useAuthStore();

            try {
              const fetchedEvent = await fetchTournament(to.params.tournamentId);
              to.params.tournament = fetchedEvent.data;

              // Update breadcrumb with actual event name after fetching the event
              to.meta.breadcrumb = [
                { label: "Events", link: '/events' },
                { label: to.params.tournament.event.name, link: `/event/${to.params.tournament.event.id}` },
                { label: to.params.tournament.name }
              ];

            } catch {}

            next();

          },
        },
        {
          path: "/tournament/team/:tournamentTeamId/complete-registration/:teamMemberId?",
          name: "complete-registration",
          component: () => import("@/pages/app/team/PlayerRegistration/PlayerRegistration.vue"),
          meta: {
            heading: 'Complete Registration',
            custom_auth: {
              required: true,
              roles:[],
              permissions:[]
            },
            breadcrumb: route => [
              // { label: "Events", link: '/events' },
              // { label: "Events", link: '/events' },
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchTournamentTeam } = useTeamsApi();
            const authStore = useAuthStore();

            try {
              const fetchedTournamentTeam = await fetchTournamentTeam(to.params.tournamentTeamId);
              to.params.tournamentTeam = fetchedTournamentTeam.data;

              // Update breadcrumb with actual event name after fetching the event
              to.meta.breadcrumb = [
                {label: to.params.tournamentTeam.name, link: `/tournament/team/${to.params.tournamentTeam.id}`},
                {label : "Complete Registration"}
              ];

            } catch {}

            next();


          },
        },
        // {
        //   path: "/teams/view/:teamSlug",
        //   name: "view-team",
        //   component: () => import("@/pages/app/team/information/Information.vue"),
        //   meta: {
        //     heading: 'Information',
        //     parent: [
        //       {
        //         path: '/teams',
        //         heading: 'Teams'
        //       }
        //     ],
        //     custom_auth: {
        //       required: true,
        //       roles:[],
        //       permissions:[]
        //     },
        //   }
        // },
        {
          path: "/tournament/team/:tournamentTeamId",
          name: "view-tournament-team",
          component: () => import("@/pages/app/team/information/Information.vue"),
          meta: {
            heading: 'Information',
            custom_auth: {
              required: true,
              roles:[],
              permissions:[]
            },
            breadcrumb: route => [
              // { label: "Events", link: '/events' },
              // { label: "Events", link: '/events' },
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchTournamentTeam } = useTeamsApi();
            const authStore = useAuthStore();

            try {
              const fetchedTournamentTeam = await fetchTournamentTeam(to.params.tournamentTeamId);
              to.params.tournamentTeam = fetchedTournamentTeam.data;

              if(to.query.alert) {
                //get the updated user details
                await authStore.getUserDetails();
              }
              // Update breadcrumb with actual event name after fetching the event
              to.meta.breadcrumb = [
                {label: to.params.tournamentTeam.name}
              ];

            } catch {}

            next();

          },
        },
        {
          path: "/tournament/teams",
          name: "tournament-teams",
          component: () => import("@/pages/app/team/Index/Index.vue"),
          meta: {
            heading: 'Teams',
            custom_auth: {
              required: true,
              roles:[],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Your Teams", link: '' },
            ]
          },
        },
        {
          path: "/team/invitations",
          name: "invitations",
          component: () => import("@/pages/app/team/Invitation/Invitations.vue"),
          meta: {
            heading: 'Team Invitations',
            custom_auth: {
              required: true,
              roles:[],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Team Invitations", link: '' },
            ]
          },
          beforeEnter: async (to, from, next) => {
            const { fetchUsersInvitations } = useUserApi();
            const authStore = useAuthStore();

            try {
              const invitations = await fetchUsersInvitations();
              to.params.invitations = invitations.data;
            } catch {}

            next();

          },
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/pages/app/settings/Settings.vue"),
          meta: {
            heading: 'Settings',
            custom_auth: {
              required: true,
              roles:[],
              permissions:[]
            },
            breadcrumb: route => [
              { label: "Settings", link: '' },
            ]
          },
        },
        // {
        //   path: "/analytics",
        //   name: "analytics",
        //   component: () => import("@/pages/app/Analytics.vue"),
        //   meta: {
        //     heading: 'Analytics',
        //   }
        // },
        // {
        //   path: "/tournament",
        //   name: "tournament",
        //   component: () => import("@/pages/Tournament.vue"),
        // },
        {
          path: "/",
          name: "landing-page",
          component: () => import("@/pages/app/landing-page/LandingPage.vue"),
          meta: {
            heading: 'Home',
            custom_auth: {
              required: true,
              roles:[],
              permissions:[]
            }
          }
        },

        // {
        //   path: "/dashboard-overview-2",
        //   name: "dashboard-overview-2",
        //   component: () => import("@/pages/DashboardOverview2.vue"),
        // },
        // {
        //   path: "/dashboard-overview-3",
        //   name: "dashboard-overview-3",
        //   component: () => import("@/pages/DashboardOverview3.vue"),
        // },
        // {
        //   path: "/dashboard-overview-4",
        //   name: "dashboard-overview-4",
        //   component: () => import("@/pages/DashboardOverview4.vue"),
        // },
        // {
        //   path: "/dashboard-overview-5",
        //   name: "dashboard-overview-5",
        //   component: () => import("@/pages/DashboardOverview5.vue"),
        // },
        // {
        //   path: "/dashboard-overview-6",
        //   name: "dashboard-overview-6",
        //   component: () => import("@/pages/DashboardOverview6.vue"),
        // },
        // {
        //   path: "/dashboard-overview-7",
        //   name: "dashboard-overview-7",
        //   component: () => import("@/pages/DashboardOverview7.vue"),
        // },
        // {
        //   path: "/dashboard-overview-8",
        //   name: "dashboard-overview-8",
        //   component: () => import("@/pages/DashboardOverview8.vue"),
        // },
        // {
        //   path: "/users",
        //   name: "users",
        //   component: () => import("@/pages/Users.vue"),
        // },
        // {
        //   path: "/departments",
        //   name: "departments",
        //   component: () => import("@/pages/Departments.vue"),
        // },
        // {
        //   path: "/add-user",
        //   name: "add-user",
        //   component: () => import("@/pages/AddUser.vue"),
        // },
        // {
        //   path: "/profile-overview",
        //   name: "profile-overview",
        //   component: () => import("@/pages/ProfileOverview.vue"),
        // },
        // {
        //   path: "/profile-overview?page=events",
        //   name: "profile-overview-events",
        //   component: () => import("@/pages/ProfileOverview.vue"),
        // },
        // {
        //   path: "/profile-overview?page=achievements",
        //   name: "profile-overview-achievements",
        //   component: () => import("@/pages/ProfileOverview.vue"),
        // },
        // {
        //   path: "/profile-overview?page=contacts",
        //   name: "profile-overview-contacts",
        //   component: () => import("@/pages/ProfileOverview.vue"),
        // },
        // {
        //   path: "/profile-overview?page=default",
        //   name: "profile-overview-default",
        //   component: () => import("@/pages/ProfileOverview.vue"),
        // },
        // {
        //   path: "/settings?page=email-settings",
        //   name: "settings-email-settings",
        //   component: () => import("@/pages/Settings.vue"),
        // },
        // {
        //   path: "/settings?page=security",
        //   name: "settings-security",
        //   component: () => import("@/pages/Settings.vue"),
        // },
        // {
        //   path: "/settings?page=preferences",
        //   name: "settings-preferences",
        //   component: () => import("@/pages/Settings.vue"),
        // },
        // {
        //   path: "/settings?page=two-factor-authentication",
        //   name: "settings-two-factor-authentication",
        //   component: () => import("@/pages/Settings.vue"),
        // },
        // {
        //   path: "/settings?page=device-history",
        //   name: "settings-device-history",
        //   component: () => import("@/pages/Settings.vue"),
        // },
        // {
        //   path: "/settings?page=notification-settings",
        //   name: "settings-notification-settings",
        //   component: () => import("@/pages/Settings.vue"),
        // },
        // {
        //   path: "/settings?page=connected-services",
        //   name: "settings-connected-services",
        //   component: () => import("@/pages/Settings.vue"),
        // },
        // {
        //   path: "/settings?page=social-media-links",
        //   name: "settings-social-media-links",
        //   component: () => import("@/pages/Settings.vue"),
        // },
        // {
        //   path: "/settings?page=account-deactivation",
        //   name: "settings-account-deactivation",
        //   component: () => import("@/pages/Settings.vue"),
        // },
        // {
        //   path: "/billing",
        //   name: "billing",
        //   component: () => import("@/pages/Billing.vue"),
        // },
        // {
        //   path: "/invoice",
        //   name: "invoice",
        //   component: () => import("@/pages/Invoice.vue"),
        // },
        // {
        //   path: "/categories",
        //   name: "categories",
        //   component: () => import("@/pages/Categories.vue"),
        // },
        // {
        //   path: "/add-product",
        //   name: "add-product",
        //   component: () => import("@/pages/AddProduct.vue"),
        // },
        // {
        //   path: "/product-list",
        //   name: "product-list",
        //   component: () => import("@/pages/ProductList.vue"),
        // },
        // {
        //   path: "/product-grid",
        //   name: "product-grid",
        //   component: () => import("@/pages/ProductGrid.vue"),
        // },
        // {
        //   path: "/transaction-list",
        //   name: "transaction-list",
        //   component: () => import("@/pages/TransactionList.vue"),
        // },
        // {
        //   path: "/transaction-detail",
        //   name: "transaction-detail",
        //   component: () => import("@/pages/TransactionDetail.vue"),
        // },
        // {
        //   path: "/seller-list",
        //   name: "seller-list",
        //   component: () => import("@/pages/SellerList.vue"),
        // },
        // {
        //   path: "/seller-detail",
        //   name: "seller-detail",
        //   component: () => import("@/pages/SellerDetail.vue"),
        // },
        // {
        //   path: "/reviews",
        //   name: "reviews",
        //   component: () => import("@/pages/Reviews.vue"),
        // },
        // {
        //   path: "/inbox",
        //   name: "inbox",
        //   component: () => import("@/pages/Inbox.vue"),
        // },
        // {
        //   path: "/file-manager-list",
        //   name: "file-manager-list",
        //   component: () => import("@/pages/FileManagerList.vue"),
        // },
        // {
        //   path: "/file-manager-grid",
        //   name: "file-manager-grid",
        //   component: () => import("@/pages/FileManagerGrid.vue"),
        // },
        // {
        //   path: "/chat",
        //   name: "chat",
        //   component: () => import("@/pages/Chat.vue"),
        // },
        // {
        //   path: "/calendar",
        //   name: "calendar",
        //   component: () => import("@/pages/Calendar.vue"),
        // },
        // {
        //   path: "/point-of-sale",
        //   name: "point-of-sale",
        //   component: () => import("@/pages/PointOfSale.vue"),
        // },
        // {
        //   path: "/creative",
        //   name: "creative",
        //   component: () => import("@/pages/Creative.vue"),
        // },
        // {
        //   path: "/dynamic",
        //   name: "dynamic",
        //   component: () => import("@/pages/Dynamic.vue"),
        // },
        // {
        //   path: "/interactive",
        //   name: "interactive",
        //   component: () => import("@/pages/Interactive.vue"),
        // },
        // {
        //   path: "/regular-table",
        //   name: "regular-table",
        //   component: () => import("@/pages/RegularTable.vue"),
        // },
        // {
        //   path: "/tabulator",
        //   name: "tabulator",
        //   component: () => import("@/pages/Tabulator.vue"),
        // },
        // {
        //   path: "/modal",
        //   name: "modal",
        //   component: () => import("@/pages/Modal.vue"),
        // },
        // {
        //   path: "/slideover",
        //   name: "slideover",
        //   component: () => import("@/pages/Slideover.vue"),
        // },
        // {
        //   path: "/notification",
        //   name: "notification",
        //   component: () => import("@/pages/Notification.vue"),
        // },
        // {
        //   path: "/tab",
        //   name: "tab",
        //   component: () => import("@/pages/Tab.vue"),
        // },
        // {
        //   path: "/accordion",
        //   name: "accordion",
        //   component: () => import("@/pages/Accordion.vue"),
        // },
        // {
        //   path: "/button",
        //   name: "button",
        //   component: () => import("@/pages/Button.vue"),
        // },
        // {
        //   path: "/alert",
        //   name: "alert",
        //   component: () => import("@/pages/Alert.vue"),
        // },
        // {
        //   path: "/progress-bar",
        //   name: "progress-bar",
        //   component: () => import("@/pages/ProgressBar.vue"),
        // },
        // {
        //   path: "/tooltip",
        //   name: "tooltip",
        //   component: () => import("@/pages/Tooltip.vue"),
        // },
        // {
        //   path: "/dropdown",
        //   name: "dropdown",
        //   component: () => import("@/pages/Dropdown.vue"),
        // },
        // {
        //   path: "/typography",
        //   name: "typography",
        //   component: () => import("@/pages/Typography.vue"),
        // },
        // {
        //   path: "/icon",
        //   name: "icon",
        //   component: () => import("@/pages/Icon.vue"),
        // },
        // {
        //   path: "/loading-icon",
        //   name: "loading-icon",
        //   component: () => import("@/pages/LoadingIcon.vue"),
        // },
        // {
        //   path: "/regular-form",
        //   name: "regular-form",
        //   component: () => import("@/pages/RegularForm.vue"),
        // },
        // {
        //   path: "/datepicker",
        //   name: "datepicker",
        //   component: () => import("@/pages/Datepicker.vue"),
        // },
        // {
        //   path: "/tom-select",
        //   name: "tom-select",
        //   component: () => import("@/pages/TomSelect.vue"),
        // },
        // {
        //   path: "/file-upload",
        //   name: "file-upload",
        //   component: () => import("@/pages/FileUpload.vue"),
        // },
        // {
        //   path: "/wysiwyg-editor",
        //   name: "wysiwyg-editor",
        //   component: () => import("@/pages/WysiwygEditor.vue"),
        // },
        // {
        //   path: "/validation",
        //   name: "validation",
        //   component: () => import("@/pages/Validation.vue"),
        // },
        // {
        //   path: "/chart",
        //   name: "chart",
        //   component: () => import("@/pages/Chart.vue"),
        // },
        // {
        //   path: "/slider",
        //   name: "slider",
        //   component: () => import("@/pages/Slider.vue"),
        // },
        // {
        //   path: "/image-zoom",
        //   name: "image-zoom",
        //   component: () => import("@/pages/ImageZoom.vue"),
        // },
      ],
    },
    // {
    //   path: "/landing-page",
    //   name: "landing-page",
    //   component: () => import("@/pages/LandingPage.vue"),
    // },
    {
      path: "/login/:alert?",
      name: "login",
      component: () => import("@/pages/app/auth/Auth.vue"),
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/pages/app/auth/Auth.vue"),
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/pages/app/auth/Auth.vue"),
    },
    {
      path: "/verify-email/:token",
      name: "verify-email",
      component: () => import("@/pages/app/auth/Auth.vue"),
      beforeEnter: async (to, from, next) => {
        const { verifyEmail, resendEmailVerification } = useAuthApi();
        const {setItem} = useLocalStorage();

        const token = to.params.token;

        var response;

        try {
          response = await verifyEmail(token);
          // Redirect to the named route 'login' with a query parameter
          setItem('login_email', response.email);
          next({ name: 'login', params: { alert: 'email-verified' } });
        } catch (error) {

          // If we have invalid signature it means the Verification link has expired.
          if (error.response && error.response.data.message === "Email Token Expired") {
            try {
              //we try and resend the link
              const innerResponse = await resendEmailVerification({email:error.response.data.email});
              next({name: 'login', params: {alert: 'email-token-expired'}});
            } catch(error) {
              alert(error.response && error.response.data && error.response.data.message
                  ? error.response.data.message
                  : 'An unexpected error occurred. Please try again.');
            }
          }
          else if (error.response && error.response.data.message === "Email Token not found") {
            next({name: 'login', params: {alert: 'email-token-not-found'}});
          }
          else {
            console.log(error)
            console.log(error.response)
            // Handle other errors
            alert("There was a problem verifying your email. Please try again later.")
          }

          next({ name: 'login'});
        }
      },
    },

    {
      path: "/reset-password/:token",
      name: "reset-password",
      component: () => import("@/pages/app/auth/Auth.vue"),
      beforeEnter: async (to, from, next) => {
        const { resetPasswordCheck, resendPasswordReset } = useAuthApi();
        const {setItem} = useLocalStorage();

        const token = to.params.token;


        var response;

        try {
          response = await resetPasswordCheck(token);
          // Redirect to the named route 'login' with a query parameter
          setItem('login_email', response.email);
          next();
        } catch (error) {

          // If we have invalid signature it means the Verification link has expired.
          if (error.response && error.response.data.message === "Email Token Expired") {
            try {
              //we try and resend the link
              const innerResponse = await resendPasswordReset({email:error.response.data.email});
              next({name: 'login', params: {alert: 'reset-password-token-expired'}});
            } catch(error) {

              alert(error.response && error.response.data && error.response.data.message
                  ? error.response.data.message
                  : 'An unexpected error occurred. Please try again.');
            }
          }
          else if (error.response && error.response.data.message === "Email Token not found") {
            next({name: 'login', params: {alert: 'reset-password-token-not-found'}});
          }
          else {
            console.log(error)
            console.log(error.response)
            // Handle other errors
            alert("There was a problem resetting your password. Please try again later.")
          }

          next({ name: 'forgot-password'});
        }
      },
    },
  ],

});




export { router }
import { router } from "@/router";
import { createAuth } from "vue-auth3"

import driverAuthBearer from "vue-auth3/drivers/auth/bearer-token"
import driverHttpAxios from "vue-auth3/drivers/http/axios"

const auth = createAuth({
    fetchData: {
        enabled: false, // send a request to `/api/user` if the user information stored in the cookie is not visible
        cache: false, //save user information to localStorage for use
        enabledInBackground: false, // refresh user information in the background
    },
    refreshToken: {
        enabled: false, // refresh token in goto page
        enabledInBackground: true, // refresh token in background
        interval:30
    },    
  plugins: {
    router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
  },
})

export default auth;

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import _ from "lodash";
import { twMerge } from "tailwind-merge";
import { computed, type InputHTMLAttributes, useAttrs, inject, ref, watch } from "vue";
import { type ProvideFormInline } from "./FormInline.vue";
import { type ProvideInputGroup } from "./InputGroup/InputGroup.vue";
import { useField } from 'vee-validate';
import { useStringManipulation } from "@/helpers/string-manipulation";

const { capitaliseFirstLetter } = useStringManipulation();

interface FormInputProps extends /* @vue-ignore */ InputHTMLAttributes {
  modelValue?: InputHTMLAttributes["value"];
  formInputSize?: "sm" | "lg";
  rounded?: boolean;
  name?: string;  // Made optional for non-VeeValidate use
  relativeError?: boolean;
}

const props = defineProps<FormInputProps>();
const emit = defineEmits(['update:modelValue']);
const attrs = useAttrs();
const formInline = inject<ProvideFormInline>("formInline", false);
const inputGroup = inject<ProvideInputGroup>("inputGroup", false);

const computedClass = computed(() =>
    twMerge([
      "disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent",
      "[&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent",
      "transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80",
      props.formInputSize == "sm" && "text-xs py-1.5 px-2",
      props.formInputSize == "lg" && "text-lg py-1.5 px-4",
      props.rounded && "rounded-full",
      formInline && "flex-1",
      inputGroup &&
      "rounded-none [&:not(:first-child)]:border-l-transparent first:rounded-l last:rounded-r z-10",
      typeof attrs.class === "string" && attrs.class,
    ])
);

let value = ref(props.modelValue || '');
let errorMessage = ref<string | null>(null);
let handleChange, handleBlur, handleInput;

// Watch for changes in the modelValue prop and update the internal value ref
watch(() => props.modelValue, (newValue) => {
  value.value = newValue;
});

// If `name` prop is provided, use VeeValidate's `useField`
if (props.name) {
  const field = useField(props.name, undefined, {
    validateOnValueUpdate: false,
  });

  value = field.value;
  errorMessage = field.errorMessage;
  handleChange = field.handleChange;
  handleBlur = field.handleBlur;
  handleInput = field.handleInput;

  // Watch for changes in the VeeValidate field value and emit them
  watch(value, (newValue) => {
    emit('update:modelValue', newValue);
  });
}

const onInput = (event: Event) => {
  const newValue = (event.target as HTMLInputElement).value;
  emit('update:modelValue', newValue);

  // Update VeeValidate's value if using validation
  if (props.name && handleChange) {
    handleChange(event, false);
  }
};

const validationListeners = {
  blur: (evt) => handleBlur && handleBlur(evt, true),
  change: (evt) => handleChange && handleChange(evt),
  input: handleInput || onInput, // Use VeeValidate's handleInput if available
};
</script>

<template>
  <div class="relative w-full">
    <input
        :class="[computedClass, errorMessage != null && '!border-red-600/80']"
        :type="props.type"
        :value="value"
        :name="props.name"
        v-bind="_.omit(attrs, 'class')"
        v-on="validationListeners"
    />
    <Transition>
      <div
          class="text-red-600/80 mt-1 text-[12px]"
          :class="!relativeError && 'absolute'"
          v-if="errorMessage"
      >
        {{ capitaliseFirstLetter(errorMessage) }}
      </div>
    </Transition>
  </div>
</template>

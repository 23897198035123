import { defineStore } from "pinia";
import { useLocalStorage } from "@/helpers/localStorage";
import {useAppApi} from "@/composables/api/useAppApi";
import { useRouter } from "vue-router";

export interface AppStore {
  currentAppVersion: string | null;
  latestAppVersion: string | null;
  googleLoginLibraryFailed: boolean;
  useAppApi: any;
  router: object;
}

export const useAppStore = defineStore("app", {
  state: (): AppStore => ({
    currentAppVersion: null,
    latestAppVersion: null,
    googleLoginLibraryFailed: false,
    useAppApi: useAppApi(),
    router: useRouter(),

  }),
  getters: {
    // Use a getter to compare versions
    isCurrentVersionLatest: (state) => {
      return state.currentAppVersion === state.latestAppVersion;
    },
  },
  actions: {
    initialiseStore() {
      // const {getItem} = useLocalStorage();
      // const currentAppVersion = getItem('app_version');
      //
      // // If the stored version is null, set the current version to latest
      // if (currentAppVersion == null) {
      //
      // } else {
      //   this.setCurrentAppVersion(currentAppVersion);
      // }

      //when we initialise the store the user must have the latest version
      this.setCurrentAppVersion(this.latestAppVersion);

      // // Check if the current version is the latest
      // if (!this.isCurrentVersionLatest) {
      //   this.handleVersionMismatch();
      // }

      // Set up a 5-minute interval to check for the latest app version
      this.scheduleVersionCheck();
    },

    setCurrentAppVersion(version: string | null) {
      this.currentAppVersion = version;
      const {setItem} = useLocalStorage();
      if (version) setItem('app_version', version);
    },

    setLatestAppVersion(version: string) {
      this.latestAppVersion = version;
    },

    handleVersionMismatch() {
      const {setItem} = useLocalStorage();
      setItem('app_version', this.latestAppVersion);
      alert('A new version of the app is available. Your page will refresh to update.');
      window.location.reload(true); // Force a hard reload
    },

    async checkLatestVersion() {
      const {getLatestAppVersion} = this.useAppApi;
      try {
        // Call your API to get the latest app version
        const result = await getLatestAppVersion();

        // Ensure result is valid
        if (result && result.data && result.data.app_version) {
          // Update the latest app version and check for a mismatch
          this.setLatestAppVersion(result.data.app_version);

          if (!this.isCurrentVersionLatest) {
            this.handleVersionMismatch();
          }
        }
      } catch (error) {
        console.error("Error fetching the latest app version:", error);
      }
    },

    scheduleVersionCheck() {

      var self = this;
      // Call the API every 5 minutes to check for the latest version
      setInterval(async () => {
        self.checkLatestVersion()
      }, 300000); // 300000 ms = 5 minutes
    },
    refreshAndRedirectToHome(message:string) {

      alert(message);

      this.router.push('/');

    }
  }
});

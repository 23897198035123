import FormCheck from "./FormCheck.vue";
import Input from "./Input.vue";
import Label from "./Label.vue";
import Wrapper from "./Wrapper.vue";

const FormCheckComponent = Object.assign({}, FormCheck, {
  Input: Input,
  Label: Label,
  Wrapper: Wrapper
});

export default FormCheckComponent;

import { type Router, type RouteLocationNormalizedLoaded } from "vue-router";
import { type Menu, type SimpleMenu } from "@/stores/menu";
import { slideUp, slideDown } from "@/utils/helper";

import { useAuthStore } from '@/stores/app/AuthStore';
import { useContactUsStore } from '@/stores/app/ContactUsStore'

export interface Route extends RouteLocationNormalizedLoaded {
  forceActiveMenu?: string;
}

export interface FormattedMenu extends Menu {
  active?: boolean;
  activeDropdown?: boolean;
  subMenu?: FormattedMenu[];
}

export type ProvideForceActiveMenu = (pageName: string) => void;

const forceActiveMenu = (route: Route, pageName: string) => {
  route.forceActiveMenu = pageName;
};

// Setup side menu
const findActiveMenu = (subMenu: Menu[], route: Route): boolean => {
  let match = false;
  subMenu.forEach((item) => {
    if (
      ((route.forceActiveMenu !== undefined &&
        item.pageName === route.forceActiveMenu) ||
        (route.forceActiveMenu === undefined &&
          item.pageName === route.name)) &&
      !item.ignore
    ) {
      match = true;
    } else if (!match && item.subMenu) {
      match = findActiveMenu(item.subMenu, route);
    }
  });
  return match;
};

const nestedMenu = (menu: Array<Menu | SimpleMenu>, route: Route) => {
  const authStore = useAuthStore();
  const formattedMenu: Array<FormattedMenu | string> = [];
  menu.forEach((item) => {

    //if we have a role for this menu item, make sure current user is eligible
    if(item.roles && item.roles.length > 0) {
      if(authStore.doesUserHaveRole(item.roles) == false) {
        return false;
      }
    }
    if (item.type == null) {
      const menuItem: FormattedMenu = {
        icon: item.icon,
        title: item.title,
        onClick: item.onClick,
        path: item.path,
        pageName: item.pageName,
        subMenu: item.subMenu,
        ignore: item.ignore,
      };
      menuItem.active =
        ((route.forceActiveMenu !== undefined &&
          menuItem.pageName === route.forceActiveMenu) ||
          (route.forceActiveMenu === undefined &&
            menuItem.pageName === route.name) ||
          (menuItem.subMenu && findActiveMenu(menuItem.subMenu, route))) &&
        !menuItem.ignore;

      if (menuItem.subMenu) {
        menuItem.activeDropdown = findActiveMenu(menuItem.subMenu, route);

        // Nested menu
        const subMenu: Array<FormattedMenu> = [];
        nestedMenu(menuItem.subMenu, route).map(
          (menu) => typeof menu !== "string" && subMenu.push(menu)
        );
        menuItem.subMenu = subMenu;
      }

      formattedMenu.push(menuItem);
    } else {
      formattedMenu.push(item.title);
    }
  });

  return formattedMenu;
};

const linkTo = (menu: FormattedMenu, router: Router) => {
  const contactUsStore = useContactUsStore();
  if (menu.subMenu) {
    menu.activeDropdown = !menu.activeDropdown;
  }
  else if (menu.onClick) {
    // If the menu has an onClick, trigger the corresponding action
    if (menu.onClick === 'openContactPopup') {
      // Trigger your popup here

      contactUsStore.formVisible = true
      // contactUsStore.openPopup();  // Assuming you have a contactUsStore defined for popup control
    }
  }
  else {
    if(menu.path) {
      router.push(menu.path)
    }
    else if (menu.pageName !== undefined) {
      router.push({
        name: menu.pageName,
      });
    }
  }
};

const enter = (el: Element) => {
  slideDown(el as HTMLElement, 300);
};

const leave = (el: Element) => {
  slideUp(el as HTMLElement, 300);
};

export { nestedMenu, linkTo, enter, leave, forceActiveMenu };

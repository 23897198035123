<script setup lang="ts">
import { watch } from 'vue';
import { useNotificationStore } from '@/stores/app/NotificationStore';
import "@/assets/css/vendors/toastify.css";

const notificationStore = useNotificationStore();

watch(
    () => notificationStore.notifications,
    (newNotifications) => {
      newNotifications.forEach(notification => {
        if (!notification.toastInstance) {
          notificationStore.showNotification(notification.message, notification.options);
        }
      });
    },
    { deep: true }
);
</script>

<template>
  <div>
    <!-- This component does not need to render anything -->
  </div>
</template>

import { type Icon } from "@/components/Base/Lucide/Lucide.vue";
import { defineStore } from "pinia";
import { UserRoleEnum } from '@/enums'
import { useAuthStore } from "@/stores/app/AuthStore";

export interface Menu {
  icon: Icon;
  title: string;
  badge?: number;
  path?: string;
  onClick?: string;
  pageName?: string;
  subMenu?: Menu[];
  ignore?: boolean;
  roles?: UserRoleEnum;
}

export interface SimpleMenu {
  type: string;
  title: string;
  roles?: UserRoleEnum;
  onClick?:string;
}

export interface MenuState {
  baseMenu: Array<Menu | SimpleMenu>;
  dynamicMenu: Array<Menu>;
  activeMobileMenu: boolean;
}

export const useMenuStore = defineStore("menu", {
  state: (): MenuState => ({
    activeMobileMenu: false,
    baseMenu: [
      {
        type: "heading",
        title: "Tournament",
        roles: [UserRoleEnum.STANDARDUSER, UserRoleEnum.ADMIN, UserRoleEnum.MANAGER, UserRoleEnum.SUPERADMIN]
      },
      {
        icon: "Trophy",
        pageName: "team-registration",
        title: "Register Your Team",
        roles: [UserRoleEnum.STANDARDUSER, UserRoleEnum.ADMIN, UserRoleEnum.MANAGER, UserRoleEnum.SUPERADMIN]
      },
      // {
      //   icon: "UserPlus",
      //   pageName: "player-registration",
      //   title: "Register As A Player",
      // },
      {
        icon: "Component",
        path: '/team/invitations',
        pageName: "",
        title: "Your Invitations",
        roles: [UserRoleEnum.STANDARDUSER, UserRoleEnum.ADMIN, UserRoleEnum.MANAGER, UserRoleEnum.SUPERADMIN]
      },
      {
        type: "heading",
        title: "Management",
        roles: [UserRoleEnum.MANAGER]
      },


      // {
      //   icon: "Gem",
      //   pageName: "teams",
      //   title: "View Teams",
      //   roles: [UserRoleEnum.MANAGER],
      // },
      {
        icon: "Star",
        pageName: "view-events",
        title: "View Events",
        roles: [UserRoleEnum.MANAGER],
      },
      {
        icon: "Award",
        pageName: "view-tournaments",
        title: "View Tournaments",
        roles: [UserRoleEnum.MANAGER],
      },
      {
        icon: "UsersRound",
        pageName: "view-active-tournament-teams",
        title: "View Teams",
        roles: [UserRoleEnum.MANAGER],
      },
      {
        icon: "UserRoundCheckIcon",
        pageName: "view-active-players",
        title: "View Players",
        roles: [UserRoleEnum.MANAGER],
      },
      // {
      //   icon: "Medal",
      //   pageName: "",
      //   title: "Check Players In",
      //   roles: [UserRoleEnum.MANAGER, UserRoleEnum.STAFF],
      // },
      {
        type: "heading",
        title: "Competition",
        roles: [UserRoleEnum.STAFF, UserRoleEnum.MANAGER]
      },
      {
        icon: "TicketIcon",
        pageName: "team-check-in",
        title: "Check In",
        roles: [UserRoleEnum.STAFF, UserRoleEnum.MANAGER]
      },
      {
        type: "heading",
        title: "Admin",
        roles: [UserRoleEnum.ADMIN, UserRoleEnum.SUPERADMIN],
      },
      // {
      //   icon: "BookMarked",
      //   pageName: "dashboard-overview-4",
      //   title: "Event Management",
      // },
      {
        icon: "Plus",
        pageName: "create-event",
        title: "Create Event",
        roles: [UserRoleEnum.ADMIN, UserRoleEnum.SUPERADMIN],
      },
      {
        icon: "Mail",
        pageName: "email-templates",
        title: "Email Templates",
        roles: [UserRoleEnum.ADMIN, UserRoleEnum.SUPERADMIN],
      },

      {
        type: "heading",
        title: "Help"
      },
      {
        icon: "HelpCircleIcon",
        title: "Contact Us",
        pageName: "",
        onClick: "openContactPopup",
      },
      // {
      //   icon: "Users",
      //   pageName: "",
      //   title: "Users",
      //   roles: [UserRoleEnum.ADMIN, UserRoleEnum.SUPERADMIN],
      // },
      // {
      //   type: "heading",
      //   title: "Nike",
      //   roles: [UserRoleEnum.MANAGER, UserRoleEnum.PARTNER],
      // },
      // {
      //   icon: "Trophy",
      //   pageName: "",
      //   title: "Uniform Allocation",
      //   roles: [UserRoleEnum.MANAGER, UserRoleEnum.PARTNER],
      // },
      // {
      //   type: "heading",
      //   title: "Sponsors"
      // },
      // {
      //   icon: "BarChart",
      //   pageName: "analytics",
      //   title: "Analytics",
      // },
      // "APPS",
      // {
      //   icon: "GanttChartSquare",
      //   pageName: "inbox",
      //   title: "Inbox",
      //   badge: 4,
      // },
      // {
      //   icon: "MousePointerSquare",
      //   pageName: "dashboard-overview-6",
      //   title: "Cafe",
      // },      
      // {
      //   icon: "PanelRightClose",
      //   pageName: "file-manager-list",
      //   title: "File Manager List",
      // },
      // {
      //   icon: "PanelTopClose",
      //   pageName: "file-manager-grid",
      //   title: "File Manager Grid",
      // },
      // {
      //   icon: "PanelTopClose",
      //   pageName: "point-of-sale",
      //   title: "Point of Sale",
      // },
      // {
      //   icon: "MailOpen",
      //   pageName: "chat",
      //   title: "Chat",
      // },
      // {
      //   icon: "CalendarRange",
      //   pageName: "calendar",
      //   title: "Calendar",
      // },
      // "UI WIDGETS",
      // {
      //   icon: "Album",
      //   pageName: "creative",
      //   title: "Creative",
      // },
      // {
      //   icon: "ActivitySquare",
      //   pageName: "dynamic",
      //   title: "Dynamic",
      // },
      // {
      //   icon: "Keyboard",
      //   pageName: "interactive",
      //   title: "Interactive",
      // },
      // "USER MANAGEMENT",
      // {
      //   icon: "SquareUser",
      //   pageName: "users",
      //   title: "Users",
      // },
      // {
      //   icon: "CakeSlice",
      //   pageName: "departments",
      //   title: "Departments",
      // },
      // {
      //   icon: "PackagePlus",
      //   pageName: "add-user",
      //   title: "Add User",
      // },
    ],
    dynamicMenu: []
  }),
  getters: {
    fullMenu(state): Array<Menu | SimpleMenu> {
      // Combine baseMenu with dynamicMenu
      const menu = [...state.baseMenu];

      // Find index of the "team-registration" pageName item
      const index = menu.findIndex(item => item.pageName === "team-registration");

      // Insert dynamicMenu items just before "team-registration"
      if (state.dynamicMenu.length > 0 && index !== -1) {
        menu.splice(index, 0, ...state.dynamicMenu);
      }

      return menu;
    }
  },
  actions: {
    updateDynamicMenu() {
      const authStore= useAuthStore();
      const teams = authStore.user.tournament_teams;

      // Reset dynamic menu
      this.dynamicMenu = [];

      if (teams && teams.length > 0) {
        if (teams.length === 1) {
          this.dynamicMenu.push({
            icon: "Dribbble",
            path: `/tournament/team/${teams[0].id}`,
            title: "View Your Team",
          });
        } else {
          this.dynamicMenu.push({
            icon: "Dribbble",
            path: `/tournament/teams`,
            title: "View Your Teams",
          });
          // this.dynamicMenu.push({
          //   icon: "Dribbble",
          //   path: `/teams`,
          //   title: "View Your Teams",
          // });
        }
      }
    }
  }
});

import { defineStore } from "pinia";
import { useUserApi } from "@/composables/api/useUserApi";
import {useNotificationStore} from "@/stores/app/NotificationStore";
import {useUserStore} from "@/stores/app/UserStore";
import {useMenuStore} from "@/stores/menu";

// Interface for ContactUsStore
export interface ContactUsStore {
  formVisible: boolean,
  processing: boolean,
  additionalText: string|null,
  notificationStore: object,
  useUserApi: object
}

// Define the ContactUs store
export const useContactUsStore = defineStore("contactUs", {
  state: (): ContactUsStore => ({
    formVisible: false,
    processing:false,
    additionalText: '',
    notificationStore: useNotificationStore(),
    useUserApi: useUserApi(),
  }),
  actions: {
    // Show the form
    showForm(additionalText: string) {
      this.formVisible = true;
      this.additionalText = additionalText;
    },

    // Hide the form
    hideForm() {
      this.formVisible = false;
      this.processing = false;

      setTimeout(function() {
        this.additionalText = "";
      }, 2000)


    },
    // Submit the help form
    async submitForm(formData:object) {

      const { contactEnquiry } = this.useUserApi;


      try {

        if (this.processing == true) return; // Prevent further clicks while saving
        this.processing = true;

        var response = await contactEnquiry(formData);

        this.notificationStore.showNotification("Thanks for sending your request. One of our team members will be in touch soon.", {
          duration: 5000, // Make the notification last for 5 seconds
          close: true,  // Do not show close button
        });

        this.hideForm()

        setTimeout(() => { this.processing = false; }, 2000);

      } catch(error) {
        this.processing = false
        this.notificationStore.showNotification("There was a problem sending your request. Please reach out to your tournament manager.", {
          duration: 5000, // Make the notification last for 5 seconds
          close: true,  // Do not show close button
          type:'danger'
        });
      }
    }
  }
});
<script setup>
import { computed } from 'vue';
import { defineProps } from 'vue';

// Define props
const props = defineProps({
  className: {
    type: String,
    default: ''
  },
  backgroundColor: {
    type: String,
    default: 'white' // Default background color
  }
});

// Compute the combined classes
const loaderClasses = computed(() => {
  return ['loader', props.className];
});
</script>

<template>
  <div :class="loaderClasses" :style="{ background: props.backgroundColor }"></div>
</template>


<style scoped>
.loader {
  //width: 50px;
  padding: 3px;
  aspect-ratio: 1;
  border-radius: 50%;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
</style>


export function useLocalStorage() {

  function setItem(key:string, value:string) {
    return localStorage.setItem(key, value);
  }

  function getItem(key:string) {
    return localStorage.getItem(key);
  }
  function removeItem(key:string) {
    return localStorage.removeItem(key);
  }  
  return { setItem, getItem, removeItem }
}
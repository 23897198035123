const UserRoleEnum = Object.freeze({
    SUPERADMIN: 'super-admin',
    ADMIN: 'admin',
    PARTNER: 'partner',
    MANAGER: 'manager',
    STAFF: 'staff',
    STANDARDUSER: 'standard-user'
});

export default UserRoleEnum;

// src/composables/api/useUserApi.ts
import { useAuthHttp } from './useAuthHttp';
import { AxiosRequestConfig } from 'axios';

import {Tournament} from '@/interfaces/TournamentInterface';


export function useTournamentsApi() {
    const { get, post, put, del, patch } = useAuthHttp();

    const fetchTournaments = async (config: AxiosRequestConfig = {}): Promise<Tournament[]> => {
        return await get<Tournament[]>('/tournaments', config);
    };

    const fetchTournament = async (tournamentId: int, config: AxiosRequestConfig = {}): Promise<Tournament[]> => {
        return await get<Tournament[]>(`/events/tournament/${tournamentId}`, config);
    };

    const fetchActiveEventTournaments = async (config: AxiosRequestConfig = {}): Promise<Tournament[]> => {
        return await get<Tournament[]>(`/tournaments/active`, config);
    };

    const fetchActiveEventTournamentTeams = async (config: AxiosRequestConfig = {}): Promise<Tournament[]> => {
        return await get<Tournament[]>(`/tournaments/active/teams`, config);
    };


    const updateTournament = async (tournamentId: int,  formData: object, config: AxiosRequestConfig = {}): Promise<Tournament[]> => {
        return await post<Tournament[]>(`/events/tournament/${tournamentId}`, formData, config);
    };

    // const serveTournamentInformation = async (tournamentId: int, config: AxiosRequestConfig = {}): Promise<Blob> => {
    //     return await get(`/events/tournament/${tournamentId}/serve-tournament-information`,  config, true);
    // };

    return {
        fetchTournaments,
        fetchTournament,
        updateTournament,
        fetchActiveEventTournaments,
        fetchActiveEventTournamentTeams
        // serveTournamentInformation
    };
}

<script setup lang="ts">
  import { computed, ref } from 'vue';
  import Lucide from "@/components/Base/Lucide";
  import {Dialog} from "@/components/Base/Headless";
  import {FormSelect, FormTextarea} from "@/components/Base/Form";

  import { useContactUsStore } from '@/stores/app/ContactUsStore';
  const contactUsStore = useContactUsStore();
  import FormWrapper from "@/components/Base/Form/FormWrapper";
  import Button from "@/components/Base/Button";
  import {LoadingSpinner} from "@/components/Base/LoadingIcon";
  import {useFormHandler} from "@/composables/FormHandler";
  import {validationSchema} from "@/components/App/ContactUs/ContactFormValidationSchema";

  const message = ref('')


  const { values, errors, onSubmit, setFieldValue } = useFormHandler(validationSchema, handleFormSubmit, );

  //Form handler, goes into here on valid submission
  async function handleFormSubmit(formData) {

    contactUsStore.submitForm(formData)

  }

  const closeModal = () => {
    setTimeout(function() {
      setFieldValue('enquiry_type', '');
      setFieldValue('message', '');
    }, 2000)

    contactUsStore.hideForm()
  }

</script>

<template>
  <Dialog
      size="lg"
      :open="contactUsStore.formVisible"
      @close="() => closeModal"
  >

    <Dialog.Panel class="p-10 text-center">

      <div>
        <form @submit.prevent="onSubmit" v-prevent-enter-submit>
          <h2 class="font-bold text-lg">
            Get In Touch With Our Team
          </h2>
          <p v-if="contactUsStore.additionalText" class=" mt-5">
            {{ contactUsStore.additionalText }}
          </p>
          <p class=" mt-5 font-bold">
            Leave us a message below
          </p>

  <!--        <div class="mt-5 text-left">-->
  <!--          Enquiry Type-->
  <!--        </div>-->
          <div class="mt-5">
            <FormSelect
                name="enquiry_type"
                tabindex="-1"
            >
              <option disabled value="">Please select your enquiry type</option>
              <option>General Enquiry</option>
              <option>Question About Tournament</option>
              <option>Invitation Enquiry</option>
              <option>Report Problem</option>
              <option>Other</option>
            </FormSelect>
          </div>
          <div class="mt-8">
            <FormTextarea
                tabindex="-1"
                type="text"
                class="first:rounded-b-none first:md:rounded-bl-md first:md:rounded-r-none [&:not(:first-child):not(:last-child)]:-mt-px [&:not(:first-child):not(:last-child)]:md:mt-0 [&:not(:first-child):not(:last-child)]:md:-ml-px [&:not(:first-child):not(:last-child)]:rounded-none last:rounded-t-none last:md:rounded-l-none last:md:rounded-tr-md last:-mt-px last:md:mt-0 last:md:-ml-px focus:z-10"
                placeholder="Message"
                name="message"
            />
          </div>
          <div class="flex justify-center mt-8 gap-3">

            <Button
                variant="primary"
                class="px-4 border-primary/50 w-[130px] h-10"
                tabindex="-1"

            >
              <span v-if="!contactUsStore.processing">Submit</span>
              <LoadingSpinner v-else className="w-4 h-4"/>

            </Button>
            <Button variant="secondary"
                    class="px-4 h-10 w-[130px]"
                    tabindex="-1"
                    @click.prevent="
              () => {

                contactUsStore.hideForm()
              }
            "
            >
              <span class="text-nowrap">
                Close
              </span>

            </Button>

          </div>
        </form>
      </div>

    </Dialog.Panel>
  </Dialog>
</template>
